import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { createApiKey } from 'shared/utils/apiUtil';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import { ONBOARDING_TYPES } from 'app/containers/Onboarding/utils/OnboardingConstants';
import { Routes } from 'shared/constants/routes';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { v4 } from 'uuid';
import { GCP_ONBOARDING_FIELDS, GCP_ONBOARDING_STEP_TO_FIELDS, GCP_ONBOARDING_STEPS } from '../GcpOnboardingConstants';
import * as GcpOnboardingUtils from '../GcpOnboardingUtils';

export default function useFormNavigation({ screen, history, location, screens }) {
  const { trigger, formState, reset, setValue, getValues, setError } = useFormContext();
  const { errors } = formState || {};
  const { usersStore } = useRootStore();
  const triggerErrors = async () => trigger(GCP_ONBOARDING_STEP_TO_FIELDS[screen] || [], { shouldFocus: true });
  const { startOnboarding, updateOnboardingParams, cancelOnboarding, startOnboardingValidation } = useOnboarding();
  const { mutateAsync: handleStartOnboarding } = startOnboarding({
    onError: (error) => {
      setError(GCP_ONBOARDING_FIELDS.PROJECT_ID, {
        type: 'custom',
        message: error?.response?.data?.clientMessage || 'Something went wrong',
      });
    },
  });
  const onboardingType = getValues(GCP_ONBOARDING_FIELDS.ONBOARDING_TYPE);
  const { mutateAsync: handleUpdateOnboardingParams } = updateOnboardingParams();
  const { mutateAsync: handleValidateOnboarding } = startOnboardingValidation();
  const { mutateAsync: handleCancelOnboarding } = cancelOnboarding();
  useWatch(GCP_ONBOARDING_FIELDS.ONBOARDING_TYPE);
  const nextStep = useCallback(async () => {
    const isValid = await triggerErrors();
    if (!isValid) {
      return;
    }
    const params = {
      userKey: getValues(GCP_ONBOARDING_FIELDS.USER_KEY),
      apiKey: createApiKey(usersStore),
      cloudTypeId: CLOUD_TYPE_IDS.GCP,
      projectId: getValues(GCP_ONBOARDING_FIELDS.PROJECT_ID),
      billingDatasetName: getValues(GCP_ONBOARDING_FIELDS.BILLING_DATASET_NAME),
      billingTableName: getValues(GCP_ONBOARDING_FIELDS.BILLING_TABLE_NAME),
      serviceAccountKey: getValues(GCP_ONBOARDING_FIELDS.SERVICE_ACCOUNT_KEY),
      k8sDatasetId: getValues(GCP_ONBOARDING_FIELDS.K8S_DATASET_ID),
      k8sProjectId: getValues(GCP_ONBOARDING_FIELDS.K8S_PROJECT_ID),
      currencyType: getValues(GCP_ONBOARDING_FIELDS.CURRENCY_TYPE),
      accountKey: getValues(GCP_ONBOARDING_FIELDS.ACCOUNT_KEY),
      accountId: getValues(GCP_ONBOARDING_FIELDS.ACCOUNT_ID),
      accountName: getValues(GCP_ONBOARDING_FIELDS.ACCOUNT_NAME),
      tenantName: getValues(GCP_ONBOARDING_FIELDS.TENANT_NAME),
      tenantScheme: getValues(GCP_ONBOARDING_FIELDS.TENANT_SCHEMA_NAME),
      resellerCustomer: getValues(GCP_ONBOARDING_FIELDS.RESELLER_CUSTOMER),
      onboardingType: getValues(GCP_ONBOARDING_FIELDS.ONBOARDING_TYPE),
      validationStarted: getValues(GCP_ONBOARDING_FIELDS.VALIDATION_STARTED),
      accountType: getValues(GCP_ONBOARDING_FIELDS.ACCOUNT_TYPE),
      excludedLinkedAccountMatch: getValues(GCP_ONBOARDING_FIELDS.EXCLUDED_LINKED_ACCOUNT_MATCH),
      isCustomerSelfManaged: +getValues(GCP_ONBOARDING_FIELDS.IS_CUSTOMER_SELF_MANAGED),
      isReseller: +getValues(GCP_ONBOARDING_FIELDS.IS_MSP),
      autoAssignLinkedAccounts: +getValues(GCP_ONBOARDING_FIELDS.AUTO_ASSIGN_LINKED_ACCOUNTS),
    };
    if (screen === GCP_ONBOARDING_STEPS.GCP_DETAILS) {
      const searchParams = new URLSearchParams(location.search);
      const accountIdChanged =
        searchParams.get('accountId') && getValues(GCP_ONBOARDING_FIELDS.ACCOUNT_ID) !== searchParams.get('accountId');
      const generateAccountId = () => {
        const uuid = v4().substring(0, 6);
        const trimmedAccountName = getValues(GCP_ONBOARDING_FIELDS.ACCOUNT_NAME).replace(/\s/g, '').substring(0, 6);
        return `${trimmedAccountName}-${uuid}`;
      };
      if (!params.accountId) {
        const newAccountId = generateAccountId();
        setValue(GCP_ONBOARDING_FIELDS.ACCOUNT_ID, newAccountId);
        params.accountId = newAccountId;
      }
      if (!getValues(GCP_ONBOARDING_FIELDS.STARTED) || accountIdChanged) {
        if (accountIdChanged) {
          await handleCancelOnboarding({ accountId: searchParams.get('accountId') });
        }
        try {
          await handleStartOnboarding(params);
        } catch (error) {
          console.info(error);
          return;
        }
        setValue(GCP_ONBOARDING_FIELDS.STARTED, true);
        const addQueryParam = (key, value) => {
          const url = new URL(window.location.href);
          url.searchParams.set(key, value);
          window.history.pushState({}, '', url.toString());
        };
        addQueryParam('accountId', params.accountId);
      } else {
        await handleUpdateOnboardingParams(params);
      }
    }

    if (screen === GCP_ONBOARDING_STEPS.K8S_INTEGRATION) {
      params[GCP_ONBOARDING_FIELDS.VALIDATION_STARTED] = true;
      await handleUpdateOnboardingParams(params);
      await handleValidateOnboarding(params);
      setValue(GCP_ONBOARDING_FIELDS.VALIDATION_STARTED, true);
      setValue(GCP_ONBOARDING_FIELDS.VALIDATION_START_TRIGGER, true);
      return;
    }

    // go to dashboard page if it is the last screen of onboarding (invoice is already ran, so it should have data)
    if (screens && screens.findIndex((scr) => scr.id === screen) === screens.length - 1) {
      history.push(Routes.DASHBOARD);
      window.location.reload();
      return;
    }

    setValue(GCP_ONBOARDING_FIELDS.SCREEN, GcpOnboardingUtils.handleNextScreen(screen));
  }, [errors, screen, onboardingType]);

  const prevStep = useCallback(() => {
    reset({}, { keepValues: true, keepTouched: false, keepErrors: false, keepDirty: true });

    if (screen === GCP_ONBOARDING_STEPS.K8S_INTEGRATION) {
      setValue(GCP_ONBOARDING_FIELDS.VALIDATION_STARTED, false);
    }

    setValue(
      GCP_ONBOARDING_FIELDS.SCREEN,
      GcpOnboardingUtils.handlePrevScreen(screen, onboardingType === ONBOARDING_TYPES.AUTOMATIC),
    );
  }, [errors, screen, onboardingType]);

  return {
    nextStep,
    prevStep,
  };
}
