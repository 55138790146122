import React, { useState } from 'react';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import { Search } from '../Search';
import RemoveButton from '../RemoveButton';

import styles from './LinkedAccountsComponent.module.scss';

const LinkedAccountsComponentHeader = ({ selectedAccounts, search, setSearch, isCreateEditMode }) => {
  const [isSelectAll, setIsSelectAll] = useState(false);

  const selectAllComponent = (
    <div className="d-flex gap-2 align-items-center">
      Select All
      <InfoPopover>Note that accounts assigned from other roles will not be removed</InfoPopover>
    </div>
  );

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        {isCreateEditMode && <div className={styles.headerTitle}>Linked Accounts</div>}
        <Checkbox
          isChecked={isSelectAll}
          onChange={() => setIsSelectAll(!isSelectAll)}
          primary
          isSimple
          text={selectAllComponent}
        />
      </div>
      <div className={styles.headerRight}>
        <RemoveButton
          showRemoveComponent={selectedAccounts?.length > 0}
          selectedCounter={selectedAccounts?.length}
          removeClicked={() => {}}
          isMultiple
        />
        <Search search={search} setSearch={setSearch} isDebounce />
      </div>
    </div>
  );
};

export default LinkedAccountsComponentHeader;
