import React, { useMemo, useState } from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import Spinner from 'shared/components/andtComponents/Spinner';
import LinkedAccountsComponentHeader from './LinkedAccountsComponentHeader';
import LinkedAccountsComponentTable from './LinkedAccountsComponentTable';

import styles from './LinkedAccountsComponent.module.scss';

const LinkedAccountsComponent = ({ linkedAccountsData, removeLinkedAccountClicked, isLoading, columnsEnum }) => {
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [search, setSearch] = useState();
  const [isSelectAll, setIsSelectAll] = useState(false);

  const { NewTableWrapper } = useTable();

  const filteredAccountsData = useMemo(() => {
    if (!search) {
      return linkedAccountsData;
    }
    const { accountsData } = linkedAccountsData;
    const filteredData = accountsData.map((accountData) => {
      const filtered = accountData.linkedAccounts?.filter((linkedAccount) => linkedAccount.name.includes(search));
      return { ...accountData, linkedAccounts: filtered };
    });
    return { ...linkedAccountsData, accountsData: filteredData };
  }, [search, linkedAccountsData]);

  return (
    <div className={styles.cloudProvidersContainer}>
      <NewTableWrapper>
        <LinkedAccountsComponentHeader
          selectedAccounts={selectedAccounts}
          search={search}
          setSearch={setSearch}
          isSelectAll={isSelectAll}
          setIsSelectAll={setIsSelectAll}
        />
        {isLoading ? (
          <div className="position-relative">
            <Spinner />
          </div>
        ) : (
          <LinkedAccountsComponentTable
            linkedAccounts={filteredAccountsData}
            removeLinkedAccountClicked={removeLinkedAccountClicked}
            selectedAccounts={selectedAccounts}
            setSelectedAccounts={setSelectedAccounts}
            columns={columnsEnum}
          />
        )}
      </NewTableWrapper>
    </div>
  );
};

export default LinkedAccountsComponent;
