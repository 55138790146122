import React, { useState } from 'react';
import { ReactComponent as AWSSimple } from 'shared/img/cloud-providers/simple/aws.svg';
import { ReactComponent as AzureSimple } from 'shared/img/cloud-providers/simple/azure.svg';
import { ReactComponent as GCPSimple } from 'shared/img/cloud-providers/simple/gcp.svg';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';

import styles from './CloudProviderRow.module.scss';

const CloudProviderRow = ({ row }) => {
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  return (
    <div className={styles.cloudProviderRow}>
      {row.id === CLOUD_TYPE_IDS.AWS && <AWSSimple className={styles.cloudTypeIcon} />}
      {row.id === CLOUD_TYPE_IDS.AZURE && <AzureSimple className={styles.cloudTypeIcon} />}
      {row.id === CLOUD_TYPE_IDS.GCP && <GCPSimple className={styles.cloudTypeIcon} />}
      <div className={styles.cloudTypeTitle}>{row.title}</div>
      <div className={styles.cloudTypeSubTitle}>{row.title}</div>
    </div>
  );
};

export default CloudProviderRow;
