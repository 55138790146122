import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Grid, TableColumnResizing, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, SelectionState } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import {
  CustomFormatterWithTooltipAndComponent,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { Table } from 'reactstrap';
import { ReactComponent as AzureSimple } from 'shared/img/cloud-providers/simple/azure.svg';
import { ReactComponent as AWSSimple } from 'shared/img/cloud-providers/simple/aws.svg';
import { ReactComponent as GCPSimple } from 'shared/img/cloud-providers/simple/gcp.svg';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { getColumns, ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS } from '../../consts';
import RemoveButton from '../RemoveButton';

import styles from './LinkedAccounts.module.scss';

export const UserRolesNoData = (userRoles) => {
  if (!userRoles) {
    return null;
  }
  return (
    <div className={styles.noResultsWrapper}>
      <span className={styles.mainText}>No Accounts for this Role</span>
    </div>
  );
};

const PayerAccountsTable = ({ linkedAccounts, selectedAccounts, setSelectedAccounts, removeLinkedAccountClicked }) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS).map((c) => ({
      columnName: c.columnName,
      width: c.width,
    })),
  );

  const selectedAccountsIndexes = useMemo(() => {
    if (!selectedAccounts) {
      return [];
    }
    return selectedAccounts
      .map((role) => linkedAccounts?.findIndex((r) => r?.identifier?.id === role?.identifier?.id))
      .filter((index) => index !== -1);
  }, [selectedAccounts, linkedAccounts]);

  const previousSelectedIndexesRef = useRef(selectedAccountsIndexes);

  useEffect(() => {
    previousSelectedIndexesRef.current = selectedAccountsIndexes;
  }, [JSON.stringify(linkedAccounts)]);

  return (
    linkedAccounts && (
      <div className={styles.linkedAccountsContainer}>
        <Grid
          rows={linkedAccounts}
          columns={getColumns(ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS)}
          automationId="assign-linkedAccounts-to-role-data-access"
        >
          <SelectionState selection={selectedAccountsIndexes} onSelectionChange={setSelectedAccounts} />
          <IntegratedSelection />
          <DataTypeProvider
            for={[ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS.PAYER_ACCOUNT.columnName]}
            formatterComponent={(props) => (
              <CustomFormatterWithTooltipAndComponent {...props} isLeftComponent>
                {props.row.cloudType ? (
                  <div className={styles.cloudTypeIcon}>
                    {props.row.cloudType === CLOUD_TYPE_IDS.AWS && <AWSSimple />}
                    {props.row.cloudType === CLOUD_TYPE_IDS.AZURE && <AzureSimple />}
                    {props.row.cloudType === CLOUD_TYPE_IDS.GCP && <GCPSimple />}
                  </div>
                ) : (
                  <div />
                )}
              </CustomFormatterWithTooltipAndComponent>
            )}
          />
          <DataTypeProvider
            for={[ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS.REMOVE_LINKED_ACCOUNT.columnName]}
            formatterComponent={(props) => (
              <RemoveButton props={props} removeClicked={() => removeLinkedAccountClicked?.(props.row)} />
            )}
          />
          <TableWrapper virtual noDataCellComponent={() => UserRolesNoData(linkedAccounts)} height={350} />
          <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
          <TableColumnResizing
            resizingMode="nextColumn"
            columnWidths={localColumnsWidth}
            onColumnWidthsChange={setLocalColumnsWidth}
          />
          {/* <TableRowDetail contentComponent={LinkedAccounts} toggleCellComponent={CustomToggleCell} /> */}
          <Table showColumnHeaders={false} />
        </Grid>
      </div>
    )
  );
};

PayerAccountsTable.propTypes = {
  linkedAccounts: PropTypes.array.isRequired,
};

export default PayerAccountsTable;
