import React, { useMemo, useState } from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import { useAccounts } from 'users/new-user-management/hooks/reactQuery/useAccounts';
import Spinner from 'shared/components/andtComponents/Spinner';
import PayerAccountsTable from './PayerAccountsTable';
import PayerAccountsHeader from './PayerAccountsHeader';

import styles from './LinkedAccounts.module.scss';

const PayerAccounts = ({ row }) => {
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [search, setSearch] = useState();
  const [selectedCloud, setSelectedCloud] = useState(null);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const { NewTableWrapper } = useTable();

  const { fetchRoleDataAccess } = useRoles();
  const { data: roleDataAccess, isLoading: isRoleDataAccessIsLoading } = fetchRoleDataAccess(
    row?.identifier?.internalName,
  );

  const { fetchBillingAccountsData } = useAccounts();
  const { data: accountsData, isLoading: isAccountsDataLoading } = fetchBillingAccountsData();

  const accountsDataToDisplay = useMemo(() => {
    if (!accountsData || !roleDataAccess) {
      return null;
    }
    return roleDataAccess.map((org) => {
      const account = accountsData.find((acc) => acc.id === org.ownerOrganization);
      return { ...org, cloudType: account?.cloudType };
    });
  }, [accountsData, roleDataAccess]);

  return isAccountsDataLoading || isRoleDataAccessIsLoading ? (
    <Spinner />
  ) : (
    <div className={styles.linkedAccounts}>
      <NewTableWrapper>
        <PayerAccountsHeader
          selectedAccounts={selectedAccounts}
          search={search}
          setSearch={setSearch}
          selectedCloud={selectedCloud}
          setSelectedCloud={setSelectedCloud}
          isSelectAll={isSelectAll}
          setIsSelectAll={setIsSelectAll}
        />
        <PayerAccountsTable
          linkedAccounts={accountsDataToDisplay}
          removeLinkedAccountClicked={() => {}}
          selectedAccounts={selectedAccounts}
          setSelectedAccounts={setSelectedAccounts}
        />
      </NewTableWrapper>
    </div>
  );
};

export default PayerAccounts;
