import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import CustomModal from 'shared/components/andtComponents/Modal';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import Button from 'shared/components/andtComponents/Button';
import { ReactComponent as GCPIcon } from 'shared/img/cloud-providers/simple/gcp.svg';
import { ReactComponent as CloudsDimmed } from 'app/containers/OnboardingWelcome/assets/clouds-dimmed.svg';
import onboardingStyles from 'app/containers/Onboarding/SharedPages/Footer/Footer.module.scss';
import { ONBOARDING_TYPES } from 'app/containers/Onboarding/utils/OnboardingConstants';
import ListWithHelp from 'shared/components/andtComponents/ListWithHelp/ListWithHelp';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import { Routes } from 'shared/constants/routes';
import { ReactComponent as ManualImg } from './assets/manual.svg';
import { ReactComponent as AutomaticImg } from './assets/automatic.svg';
import { GCP_ONBOARDING_FIELDS } from '../../GcpOnboardingConstants';
import AccountDetailsHelp from './AccountDetailsHelp';
import styles from './AccountDetailsModal.module.scss';
import CustomerInput from '../../../SharedPages/CustomerInput/CustomerInput';

const AccountDetailsModal = ({ nextStep }) => {
  const { getValues, setValue } = useFormContext();
  const accountType = getValues(GCP_ONBOARDING_FIELDS.ACCOUNT_TYPE);
  const setAccountType = (value) => {
    setValue(GCP_ONBOARDING_FIELDS.ACCOUNT_TYPE, value);
    if (value === 'shared') {
      setValue(GCP_ONBOARDING_FIELDS.RESELLER_CUSTOMER, '');
    }
  };
  const onboardingType = getValues(GCP_ONBOARDING_FIELDS.ONBOARDING_TYPE);
  const setOnboardingType = (value) => {
    setValue(GCP_ONBOARDING_FIELDS.ONBOARDING_TYPE, value);
  };
  const autoAssignLinkedAccounts = getValues(GCP_ONBOARDING_FIELDS.AUTO_ASSIGN_LINKED_ACCOUNTS);
  const setAutoAssignLinkedAccounts = (value) => setValue(GCP_ONBOARDING_FIELDS.AUTO_ASSIGN_LINKED_ACCOUNTS, value);
  const renderSelectOnboardingType = () => (
    <div>
      <p className={styles.subTitle}>How would you like to complete the onboarding?</p>
      <div className={styles.selectBlock} data-onboarding="true">
        <RadioButtonList
          options={[
            {
              value: ONBOARDING_TYPES.AUTOMATIC,
              key: 1,
              label: (
                <span>
                  Automatically <i>Coming Soon!</i>
                </span>
              ),
              primary: true,
              disabled: true,
            },
            {
              value: ONBOARDING_TYPES.MANUAL,
              key: 2,
              label: 'Manually',
              primary: true,
            },
          ]}
          value={onboardingType}
          onChange={(val) => {
            setOnboardingType(val);
          }}
        />
        <div className={styles.onboardingTypeIllustration}>
          {onboardingType === ONBOARDING_TYPES.MANUAL ? <ManualImg /> : <AutomaticImg />}
        </div>
      </div>
    </div>
  );
  const renderSelectMSPAccountType = () => (
    <div>
      <p className={styles.subTitle}>Which type of account do you want?</p>
      <div className={styles.selectBlock}>
        <div className={styles.accountTypes}>
          <div className={classNames(styles.accountTypeBlock, { [styles.active]: accountType === 'dedicated' })}>
            <div className={styles.accountTypeHeader}>
              <RadioButtonList
                options={[{ value: 'dedicated', label: 'Dedicated Account', primary: true }]}
                value={accountType}
                onChange={(val) => {
                  setAccountType(val);
                }}
              />
            </div>
            <div className={styles.accountTypeBody}>
              <div className={styles.customerInfo}>
                <CustomerInput />
                <Checkbox
                  className={styles.checkbox}
                  isChecked={!!autoAssignLinkedAccounts}
                  isDisabled={getValues(GCP_ONBOARDING_FIELDS.IS_CUSTOMER_SELF_MANAGED)}
                  onChange={() => setAutoAssignLinkedAccounts(!autoAssignLinkedAccounts)}
                  text="Auto assign projects to the customer"
                />
              </div>
            </div>
          </div>
          <div className={classNames(styles.accountTypeBlock, { [styles.active]: accountType === 'shared' })}>
            <div className={styles.accountTypeHeader}>
              <RadioButtonList
                options={[{ value: 'shared', label: 'Shared Account', primary: true }]}
                value={accountType}
                onChange={(val) => {
                  setAccountType(val);
                }}
              />
            </div>
            <div className={styles.accountTypeBody}>
              <Link
                className={styles.link}
                to={{
                  pathname: Routes.CUSTOMERS_MANAGE,
                }}
              >
                <GenerateIcon iconName={ICONS.users.name} />
                Go to <span>Manage Customers</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.background}>
      <div className={styles.bgFooter}>
        <CloudsDimmed className={styles.bgClouds} />
      </div>
      <CustomModal
        open
        closeOnOutside={false}
        overrideStyles={{
          width: 968,
        }}
        size="big"
        onClose={() => {}}
        className={{
          container: styles.modalContainer,
          modal: `${styles.modalBody} ${getValues(GCP_ONBOARDING_FIELDS.IS_MSP) ? '' : styles.direct}`,
          title: styles.modalTitle,
        }}
        closeOnSave={false}
        title={
          <div className={styles.header}>
            <div className={styles.headerContent}>Account details and onboarding experience</div>
            <div className={styles.headerLogo}>
              <GCPIcon />
            </div>
          </div>
        }
        footerDisabled
      >
        <div className={styles.body}>
          <ListWithHelp
            styles={{
              listGap: '24px',
              helpWidth: 400,
            }}
            help={{
              'onboarding-type': AccountDetailsHelp.OnboardingType,
              'msp-account-type': AccountDetailsHelp.MSPAccountType,
            }}
            list={[
              {
                render: renderSelectOnboardingType,
                key: 'onboarding-type',
              },
              {
                render: renderSelectMSPAccountType,
                key: 'msp-account-type',
                disabled: !getValues(GCP_ONBOARDING_FIELDS.IS_MSP),
              },
            ]}
          />
        </div>
        <div className={onboardingStyles.footer}>
          <div />
          <div className={onboardingStyles.footerMenu}>
            <Button
              onClick={async () => {
                await nextStep();
              }}
              text="Next"
              iconPlacement="right"
              icon={() => <GenerateIcon iconName={ICONS.chevronRight.name} />}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

AccountDetailsModal.propTypes = {
  nextStep: PropTypes.func.isRequired,
};

export default AccountDetailsModal;
