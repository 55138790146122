import React, { useState } from 'react';
import { Grid, TableRowDetail, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, RowDetailState, SelectionState } from '@devexpress/dx-react-grid';
import { Table } from 'reactstrap';
import {
  CustomSelectionCell,
  CustomToggleCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { getColumns, LINKED_ACCOUNTS_BY_CLOUD_TYPE_COLUMNS } from '../../consts';
import PayerAccounts from './PayerAccounts';
import CloudProviderRow from './CloudProviderRow';

import styles from './LinkedAccountsComponent.module.scss';

const CLOUD_PROVIDERS = {
  AWS: { id: CLOUD_TYPE_IDS.AWS, title: 'AWS' },
  AZURE: { id: CLOUD_TYPE_IDS.AZURE, title: 'Azure' },
  GCP: { id: CLOUD_TYPE_IDS.GCP, title: 'GCP' },
};

const LinkedAccountsComponentTable = ({
  linkedAccounts,
  removeLinkedAccountClicked,
  selectedAccounts,
  setSelectedAccounts,
  columns,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [cloudProvidersExpandedRowIds, setCloudProvidersExpandedRowIds] = useState(
    Object.keys(CLOUD_PROVIDERS).reduce((acc, key) => {
      acc[key] = []; // Assign an empty array to each key
      return acc;
    }, {}),
  );

  const onExpandedRowIdsChange = (expandedRowIds, cloudProvider) => {
    setCloudProvidersExpandedRowIds({ ...cloudProvidersExpandedRowIds, [cloudProvider.id]: expandedRowIds });
  };

  return (
    <div className={styles.cloudProviders}>
      {Object.values(CLOUD_PROVIDERS).map((cloudProvider) => (
        <div className={styles.cloudProviderGrid}>
          <Grid
            rows={[cloudProvider]}
            columns={getColumns(LINKED_ACCOUNTS_BY_CLOUD_TYPE_COLUMNS)}
            automationId={`assign-linkedAccounts-to-role-data-access-${cloudProvider.title}`}
          >
            <SelectionState selection={selectedRows} onSelectionChange={setSelectedRows} />
            <IntegratedSelection />
            <RowDetailState
              expandedRowIds={cloudProvidersExpandedRowIds[cloudProvider.id]}
              onExpandedRowIdsChange={(expandedRowIds) => onExpandedRowIdsChange(expandedRowIds, cloudProvider)}
            />
            <DataTypeProvider
              for={[LINKED_ACCOUNTS_BY_CLOUD_TYPE_COLUMNS.CLOUD_TYPE.columnName]}
              formatterComponent={CloudProviderRow}
            />
            <TableWrapper virtual height="fit-content" />
            <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
            <TableRowDetail contentComponent={PayerAccounts} toggleCellComponent={CustomToggleCell} />
            <Table showColumnHeaders={false} />
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default LinkedAccountsComponentTable;
