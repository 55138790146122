import React, { useMemo } from 'react';
import classNames from 'classnames';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';
import LinkedAccountsComponent from '../../../components/LinkedAccounts/LinkedAccountsComponent';

import styles from './DataAccessTab.module.scss';

const DATA_ACCESS_TABS = {
  ACCOUNTS: { id: 'accounts', label: 'Accounts' },
  COST_CENTERS: { id: 'costCenters', label: 'Cost Centers' },
};

const DataAccessTab = ({ row }) => {
  const [selectedTab, setSelectedTab] = React.useState(DATA_ACCESS_TABS.ACCOUNTS);

  const onTabClick = (tab) => {
    if (tab !== selectedTab) {
      setSelectedTab(tab);
    }
  };

  const isTabActive = (tab) => tab.id === selectedTab.id;

  const { fetchDataAccessPayerAccounts } = useDataAccessAccounts();
  const { data: accountsData, isLoading: isAccountsDataLoading } = fetchDataAccessPayerAccounts();

  const { fetchRoleDataAccess } = useRoles();
  const { data: roleDataAccess, isLoading: isRoleDataAccessIsLoading } = fetchRoleDataAccess(
    row?.identifier?.internalName,
  );

  const dataToDisplay = useMemo(() => {
    if (!accountsData || !roleDataAccess) {
      return null;
    }
    const combinedData = {
      totalPayerAccounts: accountsData?.length,
      totalLinkedAccounts: accountsData?.flatMap((pa) => pa.linkedAccounts),
      accountsData: [],
    };
    if (roleDataAccess?.payerAccounts?.allLinkedAccountsOfAllPayerAccounts) {
      combinedData.accountsData = [...accountsData];
    } else {
      const selectedPayerLinkedAccounts = roleDataAccess?.payerAccounts?.payerToLinkedAccounts;
      combinedData.accountsData = Object.keys(selectedPayerLinkedAccounts).map((payerAccountId) => {
        const payerAccountData = accountsData.find((acc) => acc.payerAccount?.id === payerAccountId);
        if (selectedPayerLinkedAccounts[payerAccountId]?.all === true) {
          return payerAccountData;
        }

        const linkedAccounts = selectedPayerLinkedAccounts[payerAccountId]?.items?.map((linkedAccountId) =>
          payerAccountData.linkedAccounts?.find((la) => la.id === linkedAccountId),
        );
        return {
          ...payerAccountData,
          linkedAccounts,
        };
      });
    }
    return combinedData;
  }, [accountsData, roleDataAccess]);

  const removeLinkedAccountClicked = () => {};

  const renderTab = () => {
    let tabComponent;
    switch (selectedTab.id) {
      case DATA_ACCESS_TABS.ACCOUNTS.id:
        tabComponent = (
          <LinkedAccountsComponent
            linkedAccountsData={dataToDisplay}
            removeLinkedAccountClicked={removeLinkedAccountClicked}
            isLoading={isAccountsDataLoading || isRoleDataAccessIsLoading}
          />
        );
        break;
      case DATA_ACCESS_TABS.COST_CENTERS.id:
        tabComponent = <div>Cost Centers</div>;
        break;
      default:
        tabComponent = null;
    }
    return tabComponent;
  };

  return (
    <div className={styles.dataAccessContainer}>
      <div className={styles.menu}>
        <div className={styles.dataAccessTabs}>
          {Object.values(DATA_ACCESS_TABS).map((tab) => (
            <div
              className={classNames(styles.dataAccessTab, { [styles.isActive]: isTabActive(tab) })}
              onClick={() => onTabClick(tab)}
              key={tab.id}
            >
              <div key={tab.id} className={styles.title}>
                {tab.label}
              </div>
              <div className={classNames(styles.selectionIndicator, { [styles.isActive]: isTabActive(tab) })}>
                {isTabActive(tab) ? (
                  <GenerateIcon iconName={ICONS.caretRight.name} className={styles.indicator} />
                ) : null}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.side} />
      </div>
      <div className={styles.data}>{renderTab()}</div>
    </div>
  );
};

export default DataAccessTab;
