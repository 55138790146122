import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Grid,
  TableColumnResizing,
  TableRowDetail,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedSelection,
  RowDetailState,
  SelectionState,
  SortingState,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid';
import { ReactComponent as NotContain } from 'shared/img/icons/not-contain.svg';
import { ReactComponent as Contains } from 'shared/img/icons/contains.svg';
import { ReactComponent as Equal } from 'shared/img/icons/equal.svg';
import { ReactComponent as NotEqual } from 'shared/img/icons/not-equal.svg';
import { ReactComponent as GreaterThan } from 'shared/img/icons/greater-than.svg';
import { ReactComponent as LessThan } from 'shared/img/icons/less-than.svg';
import { ReactComponent as GreaterThanOrEqual } from 'shared/img/icons/greater-than-or-equal.svg';
import TableWrapper from 'shared/components/tables/TableWrapper';
import useTable from 'shared/hooks/customHooks/useTable';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { debounce } from 'lodash';
import { TagColumn } from 'shared/components/tagColumn/TagColumn';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import { palette } from 'shared/constants/colorsConstants';
import { ACTION_STATUS, ACTION_TYPE, FILTERS, getSavingColumnHeader } from 'recommendationsNew/consts';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import { usePermissions } from 'app/contexts/PermissionsContext';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import { useFilterStatus } from 'recommendationsNew/hooks/useFilterStatus';
import RecommendationDataContainer from '../detailedRecommendation/recommendationDataContainer';
import { ReactComponent as NoResults } from '../../img/no-results.svg';
import RecommendationActions from './recommendationActions';
import BulkOperationsRow from './bulkOperationsRow';
import RecommendationComments from './recommendationComments';
import RecommendationUserStatus from './recommendationUserStatus';
import RecommendationDetailsWidthContext from '../../contexts/recommendationDetailsWidthContext';
import classes from './recommendationsList.module.scss';

const containOperator = ['contains'];
const numericFilterOperations = ['greaterThan', 'lessThan'];
const defaultFilterOperations = ['contains', 'notContains', 'equal', 'notEqual'];
const fixedColumns = ['comment', 'star', 'status', 'userStatus', 'userActions'];
const CustomFilterIcon = ({ type }) => {
  switch (type) {
    case 'contains':
      return <Contains />;
    case 'notContains':
      return <NotContain />;
    case 'equal':
      return <Equal />;
    case 'notEqual':
      return <NotEqual />;
    case 'greaterThan':
      return <GreaterThan />;
    case 'lessThan':
      return <LessThan />;
    case 'greaterThanOrEqual':
      return <GreaterThanOrEqual />;
    default:
      return <Contains />;
  }
};

const CustomFormatterWithTooltip = React.memo(({ value, isCentered }) => (
  <div className={isCentered ? classes.center : ''}>
    <Tooltip title={value}>
      <span>{value}</span>
    </Tooltip>
  </div>
));
const CurrencyNumberWithTooltipFormatter = React.memo(({ value, className }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const formattedValue = getCurrencyNumber(value);
  return (
    <Tooltip title={formattedValue}>
      <span className={className}>{formattedValue}</span>
    </Tooltip>
  );
});
const LabelsFormatter = React.memo(({ value }) => {
  const formattedValue = value?.replace(/,/g, ', ');
  return (
    <Tooltip title={formattedValue}>
      <span>{formattedValue}</span>
    </Tooltip>
  );
});
const CustomHeaderCell = ({ column, ...restProps }) => {
  const { children } = restProps;
  const { statusFilter, name, title } = column;
  if (!column.title) {
    return (
      <TableHeaderRow.Cell {...restProps} column={column}>
        <Tooltip title={title}>
          <span className={classes.headerList} />
        </Tooltip>
      </TableHeaderRow.Cell>
    );
  }
  return (
    <TableHeaderRow.Cell {...restProps} column={column}>
      {name === 'savings' &&
      (statusFilter.isCompleted || statusFilter.isDone) &&
      (statusFilter.isOpen || !statusFilter.isDone) ? (
        <span className={classes.headerList}>
          {children}
          <InfoPopover isSimple>
            Annual Potential/Actual Savings depends on the “Open/Completed” recommendation status.
          </InfoPopover>
        </span>
      ) : (
        <Tooltip title={title}>
          <span className={classes.headerList}>{children}</span>
        </Tooltip>
      )}
    </TableHeaderRow.Cell>
  );
};

const CustomFilterRow = ({ children, ...restProps }) => (
  <TableFilterRow.Row {...restProps} className={`filterRow ${classes.filterRow}`}>
    {children}
  </TableFilterRow.Row>
);

const CustomFilterCell =
  (dynamicHideColumns) =>
  ({ column, classes, ...restProps }) => {
    const commonColumns = [
      'assignee',
      'star',
      'comment',
      'status',
      'userStatus',
      'userActions',
      'userActionCreated',
      'userActionDate',
      'lastProcessingDate',
      'createdAtDateTime',
      'excludedPeriod',
      'excludedReason',
      'recId',
    ];
    if ([...commonColumns, ...dynamicHideColumns.map((c) => c.name)].includes(column.name)) {
      return <TableFilterRow.Cell {...restProps}> </TableFilterRow.Cell>;
    }
    return <TableFilterRow.Cell {...restProps} className="filterPlaceHolder" automation-id="input-filter" />;
  };
const getFilterColumnValue = (operator, value) => {
  switch (operator) {
    case 'contains':
      return { like: [value] };
    case 'notContains':
      return { like: [value], negate: true };
    case 'equal':
      return { eq: [value] };
    case 'notEqual':
      return { eq: [value], negate: true };
    default:
      return {};
  }
};

const statusFormatter = React.memo(({ row }) => (
  <div className={classes.statusWrapper}>
    {row.open ? (
      <span className={classes.open} automation-id="open-rec">
        OPEN
      </span>
    ) : (
      <Tooltip title="Completed" arrow placement="left">
        <span className={classes.complete} automation-id="completed-rec">
          COMP. <GenerateIcon iconName={ICONS.check.name} />
        </span>
      </Tooltip>
    )}
  </div>
));

const SavingFormatter = (props) => <CurrencyNumberWithTooltipFormatter {...props} className={classes.savings} />;

const StarFormatter =
  (updateAllowed, userActionAsync) =>
  ({ row }) =>
    (
      <Tooltip title="Add Star" arrow placement="bottom" onClick={(e) => e.stopPropagation()}>
        <span>
          <GenerateIcon
            className={`${classes.starIcon} ${!updateAllowed ? classes.disableIcon : ''}`}
            iconName={row.starred ? ICONS.star.name : ICONS.starHollow.name}
            color={row.starred ? palette.mango[500] : palette.gray[400]}
            size="lg"
            automation-id={row.starred ? `star-column-${row.recId}` : `unstar-column-${row.recId}`}
            disabled={!updateAllowed}
            onClick={() => {
              if (updateAllowed) {
                userActionAsync({
                  action: row.starred ? ACTION_TYPE.unstar : ACTION_TYPE.star,
                  recId: row.recId,
                });
              }
            }}
          />
        </span>
      </Tooltip>
    );

const RecommendationCommentsFormatter = (refetchRecs) => (props) =>
  <RecommendationComments {...props} refetchRecs={refetchRecs} />;

const StatusFormatter = (setExcluded, setDone) => (props) => {
  const { row } = props;
  return (
    <RecommendationUserStatus
      isExcluded={row.userStatus?.status === ACTION_STATUS.excluded}
      isDone={row.userStatus?.status === ACTION_STATUS.done}
      createdBy={row.userStatus?.createdByDisplayName}
      setExcluded={() => setExcluded(props)}
      setDone={() => setDone(props)}
      {...props}
    />
  );
};

const ActionFormatter = (addLabel) => (props) => {
  const { row } = props;
  return <RecommendationActions {...props} addLabel={() => addLabel(row)} recType={row.typeId} />;
};

const CustomToggleCell = ({ style, expanded, ...restProps }) => {
  return (
    <TableRowDetail.ToggleCell style={style} {...restProps} automation-id="expand-row-icon">
      <div>
        <IconButton>
          <ExpandMoreIcon style={{ transform: expanded ? 'rotate(180deg)' : 'none' }} />
        </IconButton>
      </div>
    </TableRowDetail.ToggleCell>
  );
};

const CustomNoDataComponent = (isLoading) => {
  if (isLoading) {
    return null;
  }
  return (
    <div className={classes.noResultsWrapper}>
      <NoResults />
      <span className={classes.mainText}>No Recommendation was found</span>
      <span className={classes.subText}>Try different filters</span>
    </div>
  );
};

const CustomSelectionCell = (props) => {
  const { row } = props;

  return <TableSelection.Cell {...props} row={row} automation-id="select-rec-column" />;
};

const RecommendationsGrid = ({
  recommendationsData,
  columns,
  dynamicColumns,
  isLoading,
  showFilters,
  columnNames,
  hiddenColumnNames,
  setCurrentRow,
  setShowExcludeModal,
  refetchRecs,
  setShowLabelModal,
  fetchDataForExport,
  expandedRowIds,
  setExpandedRowIds,
  userActionAsync,
  columnsWidth,
  setColumnsWidth,
  setExternalFilters,
  sorting,
  setSorting,
}) => {
  const { NewTableRow, NewTableSelectionBar } = useTable();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const { recommendationDetailsWidthRef } = useContext(RecommendationDetailsWidthContext);
  const { getCurrencyNumber } = useUserSettingsContext();
  const { isHasPermission } = usePermissions();
  const { recommendationFilters: filtersContext } = useRecommendationsNewContext();
  const statusFilter = useFilterStatus(filtersContext);

  const updateFilters = useCallback(
    (filters) => {
      const tempFilters = {};
      filters.forEach((f) => {
        if (f.value) {
          switch (f.columnName) {
            case 'savings':
              tempFilters[FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id] = Number(f.value);
              break;
            case 'age':
              tempFilters[FILTERS.AGE_RANGE.id] =
                f.operation === 'greaterThan' ? [Number(f.value), null] : [null, Number(f.value)];
              break;
            case 'customTags':
              tempFilters.custom_tags_search_expression = f.value;
              break;
            case 'enrichmentTags':
              tempFilters.enrichment_tags_search_expression = f.value;
              break;
            default: {
              const columnName = f.columnName?.replace(/([A-Z])/g, '_$1').toLowerCase();
              const columnValue = getFilterColumnValue(f.operation, f.value);
              if (columns?.find((c) => c.name === f.columnName)?.isRecData) {
                tempFilters.rec_data = { ...tempFilters.rec_data, [columnName]: columnValue };
              } else {
                tempFilters[columnName] = columnValue;
              }
            }
          }
        }
      });
      setExternalFilters(tempFilters);
    },
    [getFilterColumnValue, setExternalFilters],
  );

  const debouncedSetFilters = debounce(updateFilters, 800);

  const onFiltersChange = useCallback((newFilters) => {
    debouncedSetFilters(newFilters);
  });
  const setExcluded = useCallback(
    (props) => {
      if (props.row.userStatus?.status === ACTION_STATUS.excluded) {
        userActionAsync({
          action: ACTION_STATUS.include,
          recId: props.row.recId,
        });
      } else {
        setCurrentRow(props.row);
        setShowExcludeModal(true);
      }
    },
    [userActionAsync, setCurrentRow, setShowExcludeModal],
  );

  const setDone = useCallback(
    (props) => {
      userActionAsync({
        action: props.row.userStatus?.status === ACTION_STATUS.done ? ACTION_STATUS.undone : ACTION_STATUS.done,
        recId: props.row.recId,
      });
    },
    [userActionAsync],
  );
  const updateAllowed = isHasPermission(OrganizationEntityCategory.Organization, Action.Update);

  const getAdditionalSummaryData = useCallback(() => {
    const currentAnnual = selectedRows
      .map((index) => recommendationsData[index]?.annualCurrentCost?.unblended || 0)
      .reduce((a, b) => a + b, 0);
    const annual = selectedRows
      .map((index) => recommendationsData[index]?.annualSavings?.unblended || 0)
      .reduce((a, b) => a + b, 0);
    return (
      <>
        <div className={classes.partWrapper}>
          <span className={`${classes.number} ${classes.green}`}>{getCurrencyNumber(annual)}</span>
          {getSavingColumnHeader(statusFilter)}
        </div>
        <div className={classes.partWrapper}>
          <span className={classes.number}>{getCurrencyNumber(currentAnnual)}</span> Current Annual Cost
        </div>
      </>
    );
  }, [selectedRows, recommendationsData]);

  useEffect(() => {
    const selectedIds = selectedRows.map((index) => recommendationsData[index]?.recId);
    setSelectedRowsIds(selectedIds);
  }, [selectedRows]);

  useEffect(() => {
    const selectedRowIds = recommendationsData.filter((rec) => selectedRowsIds.includes(rec.recId));
    const selectedRowIndex = selectedRowIds.map((rec) => recommendationsData.indexOf(rec));
    setSelectedRows(selectedRowIndex);
  }, [recommendationsData]);
  const addLabel = (row) => {
    setCurrentRow(row);
    setShowLabelModal(true);
  };
  return (
    <div ref={recommendationDetailsWidthRef}>
      {columns && (
        <Grid rows={recommendationsData} columns={columns} automationId="recommendations-list">
          <SortingState
            sorting={sorting}
            columnExtensions={[
              { columnName: 'customTags', sortingEnabled: false },
              { columnName: 'enrichmentTags', sortingEnabled: false },
              { columnName: 'assignee', sortingEnabled: false },
              { columnName: 'star', sortingEnabled: false },
              { columnName: 'comment', sortingEnabled: false },
              { columnName: 'status', sortingEnabled: false },
              { columnName: 'userStatus', sortingEnabled: false },
              { columnName: 'userActions', sortingEnabled: false },
            ]}
            onSortingChange={(sortingColumAndDirection) => setSorting(sortingColumAndDirection)}
          />
          {showFilters && <FilteringState onFiltersChange={onFiltersChange} />}
          <SelectionState selection={selectedRows} onSelectionChange={setSelectedRows} />
          <IntegratedSelection />
          <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
          <DataTypeProvider
            for={['savings']}
            availableFilterOperations={['greaterThanOrEqual']}
            formatterComponent={SavingFormatter}
          />
          <DataTypeProvider
            for={['monthlySavings']}
            availableFilterOperations={['greaterThanOrEqual']}
            formatterComponent={CurrencyNumberWithTooltipFormatter}
          />
          <DataTypeProvider
            for={['typeName']}
            formatterComponent={CustomFormatterWithTooltip}
            availableFilterOperations={defaultFilterOperations}
          />
          <DataTypeProvider
            for={['linkedAccount']}
            formatterComponent={CustomFormatterWithTooltip}
            availableFilterOperations={defaultFilterOperations}
          />
          <DataTypeProvider
            for={['resource']}
            availableFilterOperations={defaultFilterOperations}
            formatterComponent={CustomFormatterWithTooltip}
          />
          <DataTypeProvider
            for={['age']}
            formatterComponent={CustomFormatterWithTooltip}
            availableFilterOperations={numericFilterOperations}
          />
          <DataTypeProvider
            for={['region']}
            formatterComponent={CustomFormatterWithTooltip}
            availableFilterOperations={defaultFilterOperations}
          />
          <DataTypeProvider
            for={['recommendedPlan']}
            formatterComponent={CustomFormatterWithTooltip}
            availableFilterOperations={defaultFilterOperations}
          />
          <DataTypeProvider
            for={['customTags']}
            formatterComponent={TagColumn}
            availableFilterOperations={containOperator}
          />
          <DataTypeProvider
            for={['enrichmentTags']}
            formatterComponent={TagColumn}
            availableFilterOperations={containOperator}
          />
          <DataTypeProvider for={['instanceType']} availableFilterOperations={defaultFilterOperations} />
          <DataTypeProvider for={['labels']} formatterComponent={LabelsFormatter} />
          <DataTypeProvider for={['excludedReason']} formatterComponent={CustomFormatterWithTooltip} />
          <DataTypeProvider for={['star']} formatterComponent={StarFormatter(updateAllowed, userActionAsync)} />
          <DataTypeProvider for={['comment']} formatterComponent={RecommendationCommentsFormatter(refetchRecs)} />
          <DataTypeProvider for={['status']} formatterComponent={statusFormatter} />
          <DataTypeProvider for={['userStatus']} formatterComponent={StatusFormatter(setExcluded, setDone)} />
          <DataTypeProvider for={['userActions']} formatterComponent={ActionFormatter(addLabel)} />
          <DataTypeProvider
            for={dynamicColumns.map((column) => column.name)}
            formatterComponent={CustomFormatterWithTooltip}
          />
          <DataTypeProvider
            for={['annualCost']}
            availableFilterOperations={['greaterThanOrEqual']}
            formatterComponent={CurrencyNumberWithTooltipFormatter}
          />
          <DataTypeProvider for={columnNames} formatterComponent={CustomFormatterWithTooltip} />

          <TableWrapper
            virtual
            noDataCellComponent={() => CustomNoDataComponent(isLoading)}
            rowComponent={(props) => (
              <NewTableRow expandedRowIds={expandedRowIds} setExpandedRowIds={setExpandedRowIds} {...props} />
            )}
            height="auto"
          />
          <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
          <TableColumnResizing
            resizingMode="widget"
            columnWidths={columnsWidth}
            onColumnWidthsChange={setColumnsWidth}
          />
          <TableRowDetail contentComponent={RecommendationDataContainer} toggleCellComponent={CustomToggleCell} />
          <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />
          {showFilters && (
            <TableFilterRow
              showFilterSelector
              iconComponent={CustomFilterIcon}
              rowComponent={CustomFilterRow}
              cellComponent={CustomFilterCell(dynamicColumns)}
            />
          )}
          <NewTableSelectionBar selectedRows={selectedRows} additionalData={getAdditionalSummaryData()}>
            <div className={classes.bulkRow}>
              <BulkOperationsRow
                exportCsv={() => fetchDataForExport(true)}
                selectedRecs={recommendationsData.filter((r, index) => selectedRows.includes(index))}
              />
            </div>
          </NewTableSelectionBar>
          <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} />
          <TableFixedColumns rightColumns={fixedColumns} />
        </Grid>
      )}
    </div>
  );
};
export default RecommendationsGrid;

RecommendationsGrid.propTypes = {};
