import { useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { STALE_TIME } from 'users/containers/Organization/consts';
import { handleError } from './helperFunctions';
import { fetchDataAccessPayerAccounts } from './apiDataAccessAccounts';

export default function useDataAccessAccounts(isDisabled = false) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const manageAccountsQueryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS, userAccountKey];

  return {
    invalidate: () => queryClient.invalidateQueries(manageAccountsQueryKey),
    reset: () => queryClient.resetQueries(manageAccountsQueryKey),
    fetchDataAccessPayerAccounts: () =>
      useQuery(manageAccountsQueryKey, () => fetchDataAccessPayerAccounts(), {
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
  };
}
