import React from 'react';
import { Search } from '../Search';
import RemoveButton from '../RemoveButton';

import styles from './LinkedAccounts.module.scss';

const PayerAccountsHeader = ({ selectedAccounts, search, setSearch, selectedCloud, setSelectedCloud }) => {
  const onCloudFilterClick = (cloud) => {
    if (selectedCloud === cloud) {
      setSelectedCloud(null);
    } else {
      setSelectedCloud(cloud);
    }
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <div className={styles.headerTitle}>Linked Accounts</div>
      </div>
      <div className={styles.headerRight}>
        <RemoveButton
          showRemoveComponent={selectedAccounts?.length > 0}
          selectedCounter={selectedAccounts?.length}
          removeClicked={() => {}}
          isMultiple
        />
        <Search search={search} setSearch={setSearch} isDebounce />
      </div>
    </div>
  );
};

export default PayerAccountsHeader;
